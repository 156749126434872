<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <informacoes-basicas
          id="informacoes-basicas-rel"
          :baseDados.sync="baseDados"
          :nomeRelatorio.sync="nomeRelatorio"
          :todosCheckbox.sync="todosCheckbox"
          :selecionarTodos="true"
        />
      </v-col>
    </v-row>
    <v-row
      :class="esmaecerPagina"
      id="row-campos"
    >
      <v-col
        md="6"
        cols="12"
      >
        <campos-linhas
          ref="refCamposLinhas"
          id="campos-linhas"
          :campos="camposLinhas"
          :relatorio="relatorio"
          :selecionarTodos="false"
        />
      </v-col>
      <v-col
        md="6"
        cols="12"
      >
        <campos-valores
          ref="refCamposValores"
          id="campos-valores"
          :campos="camposValores"
          :relatorio="relatorio"
          :selecionarTodos="false"
        />
      </v-col>
    </v-row>
    <v-row
      :class="esmaecerPagina"
      id="row-filtros"
    >
      <v-col cols="12">
        <campos-filtros
          ref="refCamposFiltros"
          id="campos-filtros"
          :campos="campos"
          :relatorio="relatorio"
          :baseDados="baseDados"
          @loading="loading = $event"
        />
      </v-col>
    </v-row>
    <v-row
      :class="esmaecerPagina"
      id="row-btn-save"
    >
      <v-col cols="12">
        <v-btn
          id="btn-save-relatorio"
          color="primary"
          min-width="100"
          style="float: right"
          class="mt-n5 mr-0"
          @click="salvar()"
          :loading="loading"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BasesDadosService from '@/services/BasesDadosService';
import RelatoriosService from '@/services/RelatoriosService';
import camposFiltrosMixins from '@/mixins/camposFiltrosMixins';
import tourMixins from '@/mixins/tourMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [camposFiltrosMixins, tourMixins],

  components: {
    InformacoesBasicas: () =>
      import('@/components/relatorios/InformacoesBasicas'),
    CamposLinhas: () => import('@/components/relatorios/CamposLinhas'),
    CamposValores: () => import('@/components/relatorios/CamposValores'),
    CamposFiltros: () => import('@/components/relatorios/CamposFiltros')
  },

  data: () => ({
    relatorio: {},
    nomeRelatorio: '',
    baseDados: '',
    todosCheckbox: false,
    campos: [],
    camposLinhas: [],
    camposValores: [],
    loading: false,
    arrTour: [
      {
        element: '#informacoes-basicas-rel',
        intro:
          'Aqui você deverá escolher um nome para seu relatório e selecionar a base de dados desejada.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#campos-linhas',
        intro:
          'Aqui você deverá selecionar os campos da base que irão compor o relatório.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#campos-valores',
        intro: `Aqui você poderá selecionar os campos de valores que irão compor o relatório.<br><br>
        Obs: É possivel também realizar operações nos valores e/ou datas.
        `,
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#campos-filtros',
        intro:
          'Aqui você deverá adicionar filtros para processamento de seu relatório.',
        scrollTo: 'tooltip',
        position: 'top'
      },
      {
        element: '#btn-add-remove-filtro',
        intro:
          'Aqui você poderá adicionar ou remover filtros de seu relatório.',
        scrollTo: 'tooltip',
        position: 'top'
      },
      {
        element: '#btn-save-relatorio',
        intro:
          'Após seleção dos critérios anteriores clique aqui para salvar e executar o relatório.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ]
  }),

  computed: {
    esmaecerPagina() {
      return this.baseDados == '' ? 'esmaecer esmaecer-aux' : '';
    }
  },

  mounted() {
    if (this.$route.params.id) this.getRelatorio(this.$route.params.id);
    this.iniciarTourPagina();
  },

  methods: {
    getRelatorio(id) {
      RelatoriosService.getRelatorio(id)
        .then((response) => {
          const rData = response.data.data;
          this.relatorio = rData;
          this.nomeRelatorio = rData.nome;
          this.baseDados = JSON.parse(rData.estrutura_json).tabela;
        })
        .catch(() =>
          this.$toast.error('Erro ao recuperar relatorio.', '', {
            position: 'topRight'
          })
        );
    },

    baseDeDadosUpdated(tabela) {
      BasesDadosService.getBasesDadosCampos(tabela)
        .then((campos) => {
          const ignorarCampos = this.getIgnorarCamposFiltros();
          this.campos = campos.filter(
            (campo) => !ignorarCampos.includes(campo.column)
          );
          this.camposLinhas = this.campos.filter(
            (campo) => campo.type != 'numeric'
          );
          let camposValores = campos.filter(
            (campo) =>
              ['numeric', 'date', 'timestamp'].includes(campo.type) ||
              (campo.column == 'contador' &&
                !['company', 'company_id'].includes(campo.column))
          );
          this.camposValores = camposValores.sort((a, b) =>
            a.type != 'date' && b.type == 'date' ? -1 : 1
          );

          this.todosCheckbox = false;
          this.$refs.refCamposLinhas.camposSelecionados = [];
          this.$refs.refCamposValores.camposSelecionados = [];

          setTimeout(() => {
            if (this.$route.params.id) {
              this.todosCheckbox =
                this.$refs.refCamposLinhas.camposSelecionados.length === 0 &&
                this.$refs.refCamposValores.camposSelecionados.length === 0;
            }
          }, 1000);
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao recuperar campos da base de dados selecionada.',
            '',
            { position: 'topRight' }
          );
        });
    },

    salvar() {
      let data = {
        id: this.$route.params.id ? this.$route.params.id : null,
        nome: this.nomeRelatorio,
        base_dados: this.baseDados,
        campos: this.$refs.refCamposLinhas.exportCampos(),
        valores: this.$refs.refCamposValores.exportCampos(),
        filtros: this.$refs.refCamposFiltros.exportCampos(),
        agrupar_campos: this.todosCheckbox === false
      };

      data = this.validaCamposBeforeSave(data);

      if (data !== 'invalido') {
        RelatoriosService.save(data)
          .then(() => {
            this.$toast.success('Relatório salvo com sucesso.', '', {
              position: 'topRight'
            });
            this.$router.replace({
              name: routes.state.appRoutes['RELATORIOS_HISTORICO']
            });
          })
          .catch(() => {
            this.$toast.error('Erro ao salvar relatório.', '', {
              position: 'topRight'
            });
          })
          .finally(() => (this.loading = false));
      }
    },

    validaCamposBeforeSave(data) {
      if (data.filtros == 'invalido') {
        return 'invalido';
      }
      this.loading = true;

      if (data.base_dados == '' || data.nome == '') {
        this.$toast.warning('Preencha as informações básicas.', '', {
          position: 'topRight'
        });
        this.loading = false;
        return 'invalido';
      }

      if (data.campos.length == 0 && data.valores.length == 0) {
        this.$toast.warning('Selecione pelo menos um campo.', '', {
          position: 'topRight'
        });
        this.loading = false;
        return 'invalido';
      }

      if (!this.validaCamposValores(data.valores)) {
        this.$toast.warning(
          'Não selecione mais de um campo valor com a mesma função.',
          '',
          { position: 'topRight' }
        );
        this.loading = false;
        return 'invalido';
      }

      data.valores = this.filtraCamposValores(data.campos, data.valores);
      return data;
    },

    /*** Function para remover duplicatas entre Campos Linhas e Campos Valores */
    filtraCamposValores(camposLinhas, camposValores) {
      return camposValores.filter((valor) =>
        valor.hasOwnProperty('function')
          ? true
          : !camposLinhas.some((campo) => campo.column == valor.column)
      );
    },

    validaCamposValores(campos) {
      let camposAux = JSON.parse(JSON.stringify(campos));
      let isInvalid = false;

      for (let i in campos) {
        camposAux.shift();

        isInvalid = camposAux.some(
          (campoAux) =>
            campoAux.column === campos[i].column &&
            campoAux.function === campos[i].function
        );

        if (isInvalid) return false;
      }

      if (!isInvalid) return true;
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let onBeforeChangeFunction = {
        func: () => {
          let elementsEsmaecidos = document.querySelectorAll(
            '#row-campos, #row-filtros, #row-btn-save'
          );
          elementsEsmaecidos.forEach((element) => {
            element.classList.remove('esmaecer');
          });
        },
        runInStep: 1
      };

      let onBeforeExitFunction = {
        func: () => {
          let elementsEsmaecidos = document.querySelectorAll(
            '#row-campos, #row-filtros, #row-btn-save'
          );
          if (!this.$route.params.id)
            elementsEsmaecidos.forEach((element) => {
              element.classList.add('esmaecer');
            });
        }
      };

      this.iniciarTour(
        this.arrTour,
        [],
        [onBeforeChangeFunction],
        [onBeforeExitFunction]
      );
    }
  },

  watch: {
    baseDados(newValue) {
      this.baseDeDadosUpdated(newValue);
    },
    todosCheckbox(newValue) {
      this.$refs.refCamposLinhas.todosCheckbox = newValue;
      this.$refs.refCamposLinhas.camposSelecionados = [];
      this.$refs.refCamposValores.todosCheckbox = newValue;
      this.$refs.refCamposValores.camposSelecionados = [];
    }
  }
};
</script>

<style scoped>
.esmaecer {
  pointer-events: none;
  opacity: 0.4;
}
</style>
